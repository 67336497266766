class Starter{

    constructor(){

    	this.init();
    }

    init(){

        const S  = new Navigation();
        const A  = new Map();

    }

}
