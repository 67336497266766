class Website{

    constructor(){
    	this.init();
    }

    init(){

        //gestion du clic sur les formulaires radio (BLOC1 et BLOC3)
        $( ".lpg .radio-questionnnaire__item" ).click(function() {
          if(!$(this).hasClass('is-active')){
            $('.radio-questionnnaire__item').removeClass('is-active');
            $(this).addClass('is-active');
            var nbReponse = $(this).index();
            var tmp = nbReponse + 1;
            var selecteurInputCible = 'form input[type="radio"]:nth-child('+ tmp + ')';
            $(this).parent().find(selecteurInputCible).prop('checked', true);
          }

          //gestion de l'etat du bouton submit
          var element = ($( ".lpg .radio-questionnnaire__item.is-active" ));
          if(element.length > 0){
            element.parents('.parentForm').find('button[type="submit"]').removeAttr('disabled').removeClass('is-disabled');
          }
          else{
            $(this).parents('.parentForm').find('button[type="submit"]').attr('disabled').addClass('is-disabled');
          }
        });

        //gestion du clic sur les formulaires radio (BLOC2)
        $( ".lpg .js-3choix" ).click(function() {
          if(!$(this).hasClass('is-active')){
            $('.lpg .js-3choix').removeClass('is-active');
            $(this).addClass('is-active');
            var nbReponse = $(this).index();
            var tmp = nbReponse + 1;
            var selecteurInputCible = 'form input[type="radio"]:nth-child('+ tmp + ')';
            $(this).parents('.radio-choix--3choix').find(selecteurInputCible).prop('checked', true);
          }

          //gestion de l'etat du bouton submit
          var element = ($( ".lpg .js-3choix.is-active" ));
          if(element.length > 0){
            element.parents('.parentForm').find('button[type="submit"]').removeAttr('disabled').removeClass('is-disabled');
          }
          else{
            $(this).parents('.parentForm').find('button[type="submit"]').attr('disabled').addClass('is-disabled');
          }
        });

        //gestion du clic sur les formulaires radio (BLOC4)
        $( ".lpg .js-6choix" ).click(function() {
          var nbReponse = $(this).index();
          var tmp = nbReponse + 1;
          var selecteurInputCible = 'form input[type="checkbox"]:nth-child('+ tmp + ')';
          if(!$(this).hasClass('is-active')){
            $(this).addClass('is-active');
            $(this).parents('.radio-choix--6choix').find(selecteurInputCible).prop('checked', true);
          }
          else{
            $(this).removeClass('is-active');
            $(this).parents('.radio-choix--6choix').find(selecteurInputCible).prop('checked', false);
          }

          //gestion de l'etat du bouton submit
          var element = ($( ".lpg .js-6choix.is-active" ));
          if(element.length > 0){
            element.parents('.parentForm').find('button[type="submit"]').removeAttr('disabled').removeClass('is-disabled');
          }
          else{
            $(this).parents('.parentForm').find('button[type="submit"]').attr('disabled','true').addClass('is-disabled');
          }
        });

        //gestion de la fermeture de la popup
        $( ".lpg" ).on('click',function(event) {
          var target = $( event.target );
          if(!target.parents('.lpg .pop-up').length > 0){
            $('.pop-up').removeClass('is-active');
          }
        });

        $('.lpg .formulaire input, .lpg .formulaire select').on('keyup paste cut change',function(){

          if($(this).attr('type') == 'text'){
            //gestion des input text is-active
            if($(this).val().length > 0){
              $(this).addClass('is-active');
            }
            else{
              $(this).removeClass('is-active');
            }
          }
          else{
            //gestion du select is-active
            if($(this).prop('selectedIndex') > 0){
              $(this).addClass('is-active');
              $(this).parent().find('.arrow').addClass('is-active');
            }
            else{
              $(this).removeClass('is-active');
              $(this).parent().find('.arrow').removeClass('is-active');
            }
          }

          //gestion de l'etat du bouton submit
          var nbInputOK = $('.lpg .formulaire input[type="text"].is-active').length;
          var nbSelectOK = $('.lpg .formulaire select.is-active').length;
          var nbTotalChamps = $('.lpg .formulaire input[type="text"],.lpg .formulaire select').length;
          if(nbInputOK + nbSelectOK == nbTotalChamps){
            $('.lpg .formulaire button[type="submit"]').removeAttr('disabled').removeClass('is-disabled');
          }
          else{
            $('.lpg .formulaire button[type="submit"]').attr('disabled','true').addClass('is-disabled');
          }

        });

    }
}


// Entry point
document.addEventListener('DOMContentLoaded', function(){

    const website = new Website();
    const starter = new Starter();

});
