class Map{

    constructor(){
        this.lat = 0;
        this.lng = 0;

        this.init();

        this.map = null;
    }

    init(){

        let map = document.querySelector('#map');

        if( !map ) return;

        this.lat = parseFloat(map.getAttribute('data-lat'));
        this.lng = parseFloat(map.getAttribute('data-lng'));

        getScript( LPG.LEAFLETPATH,  e => {

            this.onLeafletLoaded();

        });

    }

    onLeafletLoaded(){

        this.map = L.map('map',
            {
                zoomControl: true,
                scrollWheelZoom: false
            }
        )
        .setView([this.lat, this.lng], 14);

        this.map.on('focus', () => { this.map.scrollWheelZoom.enable(); });
        this.map.on('blur',  () => { this.map.scrollWheelZoom.disable(); });

        L.tileLayer('https://api.mapbox.com/styles/v1/aurelienpetitgarage/cja9jhm8u1kr92rmtn16xqq2y/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXVyZWxpZW5wZXRpdGdhcmFnZSIsImEiOiJjamE5anJ6azcwZnBhMndxOGdjYXRrNnV0In0.rppPCg2XtUmcQcHebwrL_A', {
            attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
            maxZoom: 18
        }).addTo(this.map);

        const icon = L.icon({
            iconUrl: LPG.MARKERPATH,
            iconSize:     [16, 24],
            iconAnchor:   [16, 24]
        });

        L.marker([this.lat, this.lng], { icon }).addTo( this.map );

    }

}
