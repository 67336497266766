class Navigation{

    constructor(){

        this.dom = {
            nav : document.querySelector('#navigation .menu'),
            subMenu : document.querySelectorAll('.menu-item-has-children')
        };

        this.mobileMenuTween = null;

    	this.init();
    }

    init(){

        // Sub menus
        if( this.dom.subMenu.length > 0 ){

            // Sub-menu Mobile
            this.dom.subMenu.forEach( el => {

                    el.addEventListener('click', (e)=>{
                        const target = e.currentTarget;
                        if( target.classList.contains('menu-item') ){

                            target.querySelector('.sub-menu').classList.toggle('is-active');
                            target.classList.toggle('is-active');

                        }
                    }, false);

                    el.addEventListener('mouseenter', this.onMouseEnter, false);
                    el.addEventListener('mouseleave', this.onMouseLeave, false);

                }
            );

        }

    }

    // Submenus
    onMouseEnter( e ){

        if( window.innerWidth <  580 ) return;

        let target = e.currentTarget,
            items  = target.querySelectorAll('li');

        target.classList.add('is-active');

        TweenMax.staggerFromTo( items, 0.3, {
                y: 20,
                alpha: 0
            }, {
                y: 0,
                alpha:1,
                ease: Power3.easeOut,
                overwrite: 'all'
            },
            0.04
        );

    }

    onMouseLeave( e ){

        if( window.innerWidth <  580 ) return;

        let target = e.currentTarget,
            items  = target.querySelectorAll('li');

        TweenMax.to( items, 0.24, {
                y: 20,
                alpha:0,
                ease: Power3.easeIn,
                overwrite: 'all',
                onComplete: ()=>{ target.classList.remove('is-active'); }
            }
        );

    }

}
